import React from "react"
import { Link } from "gatsby"

const CTAblockCompo = () => (
  <section className="cta_block cta_block_img">
    <div className="layout">
      <p>Do you want the beta way to be part of your life?</p>

      <div className="link_wrapper">
        <Link to="/contact">Contact us</Link>
      </div>
    </div>
  </section>
)

export default CTAblockCompo
