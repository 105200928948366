import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import Header from "../components/shared/Header"
import CTAblockCompo from "../components/shared/CTAblock"
import PressBlockShared from "../components/shared/Press_item"

const HtmlBlock = ({ data }) => (
  <div
    className="text_wrapper"
    dangerouslySetInnerHTML={{
      __html: data,
    }}
  />
)

const Programs = ({ data }) => {
  console.log("TCL: Programs -> data", data)
  return (
    <section className="programas_wrapper">
      <div className="layout">
        <div className="title_wrap">
          <h3>Our Programs </h3>
        </div>
        <div className="programs_items_wrapper">
          {data.map((item, i) => (
            <div className="item_program" key={i}>
              <h4>{item.program_title.text}</h4>
              <div className="info_wrap">
                <HtmlBlock data={item.list.html} />
                {item.launched === "no" ? (
                  <div className="wrap">
                    <p className="launch">Launching soon</p>
                  </div>
                ) : null}
                <p className="price">
                  <span className="oneMoreTime">One time purchase </span>
                  <span className="price_tag">${item.price}</span>
                  {item.shipped === "yes" ? (
                    <span className="ship"> Shipped</span>
                  ) : null}
                </p>
              </div>
              <div className="btn_wrapper">
                <button>
                  <Link to={`/${item.program_link.uid}`}>FIND OUT MORE</Link>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const BodyText = ({ data }) => {
  console.log("TCL: BodyText -> data", data)
  return (
    <div className="bodyText">
      <div className="layout">
        <div className="block">
          <div className="info">
            <h3>{data.block_1_title.text}</h3>
            <HtmlBlock data={data.block_1_text.html} />
          </div>
          <img src={data.cover_img_1.url} alt="dasf" />
        </div>
        <div className="block">
          <img src={data.cover_img_2.url} alt="dasf" className="desk" />
          <div className="info">
            <h3>{data.block_2_title.text}</h3>
            <HtmlBlock data={data.block_2_text.html} />
          </div>
          <img src={data.cover_img_2.url} alt="dasf" className="mob" />
        </div>
        <div className="block">
          <div className="info">
            <h3>{data.block_3_title.text}</h3>
            <HtmlBlock data={data.block_3_text.html} />
          </div>
          <img src={data.cover_img_3.url} alt="dasf" />
        </div>
      </div>
    </div>
  )
}

const Process = ({ data }) => {
  const { allPrismicBlogPost, allPrismicTheProcess } = data
  const {
    block_1_title,
    block_1_text,
    block_2_title,
    block_2_text,
    block_3_title,
    block_3_text,
    programs_group,
    cover_img_1,
    cover_img_2,
    cover_img_3,
  } = allPrismicTheProcess.edges[0].node.data
  return (
    <div className="process">
      <Layout>
        <Header title="WELLNESS PROGRAM" subtitle="Beta Process & Programs" />
        <BodyText
          data={{
            block_1_title,
            block_1_text,
            block_2_title,
            block_2_text,
            block_3_title,
            block_3_text,
            cover_img_1,
            cover_img_2,
            cover_img_3,
          }}
        />
        <Programs data={programs_group} />
        <PressBlockShared data={allPrismicBlogPost.edges} />
        <CTAblockCompo />
      </Layout>
    </div>
  )
}

export default Process

export const query = graphql`
  {
    allPrismicBlogPost(limit: 2) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            texto {
              text
            }
            cover_image {
              url
            }
            media_link {
              url
            }
          }
        }
      }
    }
    allPrismicTheProcess {
      edges {
        node {
          data {
            cover_img_1 {
              url
            }
            cover_img_2 {
              url
            }
            cover_img_3 {
              url
            }
            block_1_title {
              text
            }
            block_1_text {
              html
              text
            }
            block_2_title {
              text
            }
            block_2_text {
              html
              text
            }
            block_3_title {
              text
            }
            block_3_text {
              html
              text
            }
            programs_group {
              price
              shipped
              launched
              list {
                html
                text
              }
              program_title {
                html
                text
              }
              program_link {
                uid
              }
            }
          }
        }
      }
    }
  }
`
